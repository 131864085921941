<template>
  <div class="breadcrumb">
    <el-breadcrumb separator="/">
      <transition-group name="fade-transform" mode="out-in">
        <template v-for="(item, index) in breadList">
          <el-breadcrumb-item
            :to="activeList.indexOf(item) == -1 ? '' : { path: item }"
            :key="index"
            >{{ item.title == undefined ? 'Dashboard' : item.title }}</el-breadcrumb-item
          >
        </template>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    name: 'Breadcrumb',
    data() {
      return {
        activeList: [],
      };
    },
    computed: {
      ...mapState({
        breadList: (state) => state.app.breadList,
      }),
    },
    mounted() {
      this.getBreadcrumb();
    },
    watch: {
      $route(n) {
        //获取即将进入的路由
        let route = n.fullPath;
        this.getBreadcrumb(route);
      },
    },
    methods: {
      //获取面包屑
      getBreadcrumb() {
        //activeList取出vuex面包屑的第一项和最后一项，由于中间无论多少项都是不可点击的，所以拿出来和面包屑导航进行比较
        this.activeList = [];
        this.activeList.push(this.$store.state.app.breadList[0]);
        // let breadList = this.$store.state.breadList;

        this.$store.commit('app/SET_BREAD_LIST', this.activeList);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .breadcrumb {
    float: left;
    margin-left: 10px;
    margin-top: 4px;
    color: #fff;
  }
  .el-breadcrumb {
    line-height: 54px;
    font-size: 22px;
  }
</style>
<style lang="scss">
  .el-breadcrumb__item {
    float: left;
    color: #fff !important;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    font-weight: 400;
    color: #fff !important;
    cursor: text;
  }
</style>

import { isArray, isObject } from 'lodash-es';

/*
 * @moduleName:通用工具类
 * @Author: dawdler
 * @Date: 2019-01-09 15:30:18
 * @LastModifiedBy: dawdler
 * @LastEditTime: 2019-03-22 16:31:42
 */
export default {
  getTreeData,
  each,
};

/*
each(arr, (item, children) => {
    item.value = xx;
    // 该item 包含children，因此直接赋值,不需要单独处理children里面的值
});
 * [_each description]  倒查、展平、数据回调返回回当前一条数据和子集
 * @param  {[Array]}   data     [description]
 * @param  {Function}  callback [description]
 * @param  {String}    childName[description]
 * @return {[Array]}            [description]
 * 默认使用副本,在callback处理数据，如果不使用副本，那么需要重新对treeData赋值
    treeData = each(treeData, (item, children) => {
        item.value = xx;
    });
 */
export function each(data, callback, childName = 'children') {
  let current;
  let children;
  for (let i = 0, len = data.length; i < len; i++) {
    current = data[i];
    children = [];
    if (current[childName] && current[childName].length > 0) {
      children = current[childName];
    }
    callback && callback(current, children);
    if (children.length > 0) {
      each(children, callback, childName);
    }
  }
}

/**
 * @Author yihuang",
 * @param data     数据
 * @param id       要比对的名称
 * @param val      要比对的值
 * @param name     要返回的名称
 * @param children 子集名称
 * @param isRow    是否返回这一行的数据
 * @注 迭代判断多层
 * //=======================
 * 返回这一条数据的中文名
 * let name=utils.getTreeData(arr, 'flowId', item.decategoryId, 'name');
 * //=======================
 * 返回所有匹配的数据
 * let arr=utils.getTreeData(arr, 'flowId', item.decategoryId, 'name','children',true);
 */
export function getTreeData(
  data,
  id = 'id',
  val = '',
  name = 'name',
  children = 'children',
  isRow = false
) {
  let arr = [];
  each(
    data,
    (item) => {
      if (item[id] === val) {
        arr.push(item);
      }
    },
    children
  );
  return arr.length > 0 ? (isRow ? arr : arr[0][name]) : null;
}

export function filterObject(sourceObj) {
  if (typeof sourceObj !== 'object') {
    return new Error('Must be an object');
  }
  const targetObj = {};
  Object.keys(sourceObj).forEach((key) => {
    const val = sourceObj[key];
    if (!val && val !== 0) {
      return;
    }
    if (isObject(sourceObj[key]) && !isArray(sourceObj[key])) {
      const keyArr = Object.keys(sourceObj[key]);
      keyArr.length > 1 ? filterObject(sourceObj[key]) : '';
    } else {
      targetObj[key] = val;
    }
  });
  return targetObj;
}

export function throttleFn(fn, wait = 200, trailing = false) {
  let lastExec = 0;
  let timer;

  const clear = () => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }
  };
  const filter = (invoke) => {
    const duration = wait;
    const elapsed = Date.now() - lastExec;
    if (duration <= 0) {
      lastExec = Date.now();
      invoke();
    }
    if (elapsed > duration) {
      lastExec = Date.now();
      invoke();
    } else if (trailing) {
      timer = setTimeout(() => {
        clear();
        invoke();
      }, duration);
    }
  };
  return (that, ...args) => {
    filter(() => fn.apply(that, args));
  };
}

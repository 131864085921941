import { getSourceList } from 'api/source/index';
const state = {
  sourcesList: [], //广告主ID
  sourceLoading: false, //广告主下拉loading
};
const mutations = {
  SET_SOURCE_LIST: (state, sourcesList) => {
    state.sourcesList = sourcesList;
  },
  SET_SOURCE_LOADING: (state, sourceLoading) => {
    state.sourceLoading = sourceLoading;
  },
};
const actions = {
  async getSourceIdsList({ commit }) {
    commit('SET_SOURCE_LOADING', true);

    const response = await getSourceList();
    commit('SET_SOURCE_LOADING', false);
    let sourcesList = [];
    if (response.code == 200) {
      let sourceIds = response.result;
      const CPIGorups = {
        id: 1,
        checked: false,
        label: 'CPI',
        children: [],
      };

      const CPAGorups = {
        id: 2,
        checked: false,
        label: 'CPA',
        children: [],
      };
      const SmartLinkGroups = {
        id: 3,
        checked: false,
        label: 'SmartLink',
        children: [],
      };

      const CPLGroups = {
        id: 4,
        checked: false,
        label: 'CPL',
        children: [],
      };

      const PausedPending = {
        id: 5,
        checked: false,
        label: 'Paused&Pending',
        children: [],
      };
      sourceIds.forEach((item) => {
        if (item.enable !== 'ENABLE') {
          PausedPending.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        } else if (item.payoutType == 'CPI') {
          CPIGorups.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        } else if (item.payoutType == 'CPA') {
          CPAGorups.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        } else if (item.payoutType == 'CPL') {
          CPLGroups.children.push({
            id: item.id,
            value: item.id,
            label: `${item.id} - ${item.company}`,
          });
        }
      });
      sourcesList.push(CPIGorups, CPAGorups, SmartLinkGroups, CPLGroups, PausedPending);
    }
    commit('SET_SOURCE_LIST', sourcesList);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

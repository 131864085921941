import request from 'utils/request';

const api = {
  AFFILIATE_LIST: '/api/affiliate/list',
};
export function getAffiliateList() {
  return request({
    url: api.AFFILIATE_LIST,
    method: 'get',
  });
}

import request from 'utils/request';

const api = {
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  GET_MENU_LIST: '/api/security/account/menu',
  USER_LIST: '/api/account/console/list',
  INIT_USER_INFO: '/api/account/init',
};

export function login(data) {
  return request({
    url: api.LOGIN,
    method: 'post',
    data,
  });
}

export function logout() {
  return request({
    url: api.LOGOUT,
    method: 'get',
  });
}

export function getMenuList() {
  return request({
    url: api.GET_MENU_LIST,
    method: 'get',
  });
}

export function getUserList() {
  return request({
    url: api.USER_LIST,
    method: 'get',
  });
}

export function initUserInfoApi(data) {
  return request({
    url: api.INIT_USER_INFO,
    method: 'post',
    data,
  });
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
    mounted() {
      this.$store.dispatch('app/generateFingerprint');
    },
  };
</script>
<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  body .el-table th.gutter {
    display: table-cell !important;
  }
</style>

import Router from 'vue-router';
import Vue from 'vue';
import store from '@/store/';
import NProgress from 'nprogress'; //进度条
import 'nprogress/nprogress.css';
import Home from '@/components/common/Home.vue';

Vue.use(Router);

// 路由懒加载
// const getComponent = (name, component) => () =>
//   import(`@/views/${name}/${component}.vue`);

const myRouter = new Router({
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '/Dashboard',
          component: () => import('@/views/dashboard/index.vue'),
          meta: {
            title: 'Dashboard',
          },
        },
        {
          path: '/Monitor',
          component: () => import('@/views/monitor/index.vue'),
          meta: {
            title: 'Monitor',
          },
        },
        {
          path: '/affiliate/overall',
          component: () => import('@/views/affiliate/AffiliateOverall.vue'),
          meta: {
            title: 'Affiliate Overall',
          },
        },
        {
          path: '/affiliate/detail',
          meta: {
            title: 'Affiliate Detail',
          },
          component: () => import('@/views/affiliate/AffiliateDetail.vue'),
        },
        {
          path: '/affiliate/tag',
          component: () => import('@/views/affiliate/Tag.vue'),
          meta: {
            title: 'Affiliate Tag',
          },
        },
        {
          path: '/affiliate/ddj',
          component: () => import('@/views/affiliate/DDJ.vue'),
          meta: {
            title: 'Affiliate DDJ',
          },
        },
        {
          path: '/affiliate/research',
          component: () => import('@/views/affiliate/AffResearch.vue'),
          meta: {
            title: 'Aff Research',
          },
        },
        {
          path: '/source/add',
          meta: {
            title: 'Add',
          },
          component: () => import('@/views/dashboard/index.vue'),
        },
        {
          path: '/source/overall',
          component: () => import('@/views/source/sourceOverall.vue'),
          meta: {
            title: 'Source Overall',
          },
        },
        {
          path: '/source/detail',
          meta: {
            title: 'Source Detail',
          },
          component: () => import('@/views/source/sourceDetail.vue'),
        },
        {
          path: '/offer/add',
          component: () => import('@/views/product/Add.vue'),
          meta: {
            title: 'Add',
          },
        },
        {
          path: '/product/overall',
          component: () => import('@/views/product/ProductOverall.vue'),
          meta: {
            title: 'Product Overall',
          },
        },
        {
          path: '/product/detail',
          component: () => import('@/views/product/ProductDetail.vue'),
          meta: {
            title: 'Product Detail',
          },
        },
        {
          path: '/offer/top',
          component: () => import('@/views/product/Ranking&search.vue'),
          meta: {
            title: 'Ranking search',
          },
        },
        {
          path: '/offer/detail',
          component: () => import('@/views/offer/OfferDetail.vue'),
          meta: {
            title: 'Offer Detail',
          },
        },
        {
          path: '/offer/offerLogAdv',
          component: () => import('@/views/offer/OfferlogAdv.vue'),
          meta: {
            title: 'OfferLogAdv',
          },
        },
        {
          path: '/offer/smartLink',
          component: () => import('@/views/offer/OfferSmartLink.vue'),
          meta: {
            title: 'OfferSmartLink',
          },
        },
        {
          path: '/report/index',
          component: () => import('@/views/report/CommonReport.vue'),
          meta: {
            title: 'Common Report',
          },
        },
        {
          path: '/report/adv',
          component: () => import('@/views/report/AdvReport.vue'),
          meta: {
            title: 'Adv/Source Report',
          },
        },
        {
          path: '/report/aff',
          component: () => import('@/views/report/BillReport.vue'),
          meta: {
            title: 'Aff Report',
          },
        },
        {
          path: '/report/smart',
          component: () => import('@/views/report/SmartTrackingReport.vue'),
          meta: {
            title: 'Smart Tracking Report',
          },
        },
        {
          path: '/report/postbackReport',
          component: () => import('@/views/report/PostbackReport.vue'),
          meta: {
            title: 'Postback Report',
          },
        },
        {
          path: '/report/conversionReport',
          component: () => import('@/views/report/ConversionReport.vue'),
          meta: {
            title: 'Conversion Report',
          },
        },
        {
          path: '/report/simulate',
          component: () => import('@/views/report/SimulateReport.vue'),
          meta: {
            title: 'Simulate Report',
          },
        },
        {
          path: '/report/reject',
          component: () => import('@/views/report/RejectReport.vue'),
          meta: {
            title: 'Reject Report',
          },
        },
        {
          path: '/report/direct',
          component: () => import('@/views/report/DirectReport.vue'),
          meta: {
            title: 'Direct Report',
          },
        },
        {
          path: '/report/defaultTrcffic',
          component: () => import('@/views/report/DefaultTrafficReport.vue'),
          meta: {
            title: 'Default Traffic Report',
          },
        },
        {
          path: '/report/pid',
          component: () => import('@/views/report/PidReport.vue'),
          meta: {
            title: 'Pid Report',
          },
        },
        {
          path: '/report/event',
          component: () => import('@/views/report/EventReport.vue'),
          meta: {
            title: 'Event Report',
          },
        },

        {
          path: '/clickMixReport',
          component: () => import('@/views/report/ClickMixReport.vue'),
          meta: {
            title: 'Click Mix Report',
          },
        },

        {
          path: '/mgn/console-account',
          component: () => import('@/views/account/ConsoleAccount.vue'),
          meta: {
            title: 'Account',
          },
        },
        {
          path: '/permit/role-page',
          component: () => import('@/views/account/RoleRightsSetting.vue'),
          meta: {
            title: 'Role Setting',
          },
        },
        {
          path: '/permit/group',
          component: () => import('@/views/account/GroupSetting.vue'),
          meta: {
            title: 'Group Setting',
          },
        },
        {
          path: '/permit/user-aff-source',
          component: () => import('@/views/account/AccountAff.vue'),
          meta: {
            title: 'User-Aff Setting',
          },
        },
        {
          path: '/Callback',
          component: () => import('@/views/integration/index.vue'),
          meta: {
            title: 'Callback Test / Check',
          },
        },
        {
          path: '/Confusion',
          component: () => import('@/views/confusion/index.vue'),
          meta: {
            title: 'Confusion',
          },
        },
        {
          path: '/AffsubLevel',
          component: () => import('@/views/affSubLevel/index.vue'),
          meta: {
            title: 'AffSub Level',
          },
        },
        {
          path: '/affiliateBilling',
          component: () => import('@/views/billing/AffiliateBilling.vue'),
          meta: {
            title: 'Affiliate Billing',
          },
        },
        {
          path: 'postbackBilling',
          component: () => import('@/views/billing/PostbackBilling.vue'),
          meta: {
            title: 'Postback Buckle Billing',
          },
        },
        {
          path: 'topList',
          component: () => import('@/views/topList/index.vue'),
          meta: {
            title: 'Top List',
          },
        },
        {
          path: '/af/simulation',
          component: () => import('@/views/product/AfSimulate.vue'),
          meta: {
            title: 'Af Simulate',
          },
        },
        {
          path: '/404',
          component: () => import('@/views/errorPage/404.vue'),
          meta: {
            title: '404',
          },
        },
        {
          path: '/403',
          component: () => import('@/views/errorPage/403.vue'),
          meta: {
            title: '403',
          },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/views/Login/index.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

export const setTitle = function(title) {
  if (title == 'undefined') {
    window.document.title = 'Management Console';
    return;
  }
  // title = title ? `${title}` : 'Management Console'
  window.document.title = title;
};

myRouter.beforeEach((to, from, next) => {
  NProgress.start();
  const browserHeaderTitle = to.meta.title;
  sessionStorage.setItem('store', browserHeaderTitle);
  store.commit('app/SET_BROWSERHEADERTITLE', {
    browserHeaderTitle: browserHeaderTitle,
  });
  next();
});

myRouter.afterEach(() => {
  NProgress.done(); // 结束Progress
  setTimeout(() => {
    // const browserNewHeaderTitle = store.getters.browserHeaderTitle
    setTitle(sessionStorage.getItem('store'));
  }, 0);
});
export default myRouter;

import request from 'utils/request';

const api = {
  AFFILIATE_LIST: '/api/affiliate/overall/list',
  AFFILIATE_ADD: '/api/affiliate/overall/add',
  AFFILIATE_UPDATE: '/api/affiliate/overall/update',
  AFF_USER_LIST: '/api/affiliate/overall/{affiliateId}/account',
  UPDATE_AFF_USER: '/api/affiliate/overall/updateAffAccount',
};
export function getAffiliateList(query) {
  return request({
    url: api.AFFILIATE_LIST,
    method: 'get',
    params: query,
  });
}
export function insertAffiliate(data) {
  return request({
    url: api.AFFILIATE_ADD,
    method: 'post',
    data,
  });
}
export function updateAffiliate(data) {
  return request({
    url: api.AFFILIATE_UPDATE,
    method: 'put',
    data,
  });
}
export function getAffUserList(affiliateId) {
  const url = api.AFF_USER_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
  });
}

export function updateAffUser(data) {
  return request({
    url: api.UPDATE_AFF_USER,
    method: 'put',
    data,
  });
}

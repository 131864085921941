import request from 'utils/request';

const api = {
  LOG_ADV: '/api/source/logAdv',
  SOURCE_LIST: '/api/source/list',
  ALL_SOURCE_LIST: '/api/source/allList',
};
/**
 * 获取广告主拉单日志
 * @param {*} query {sourceIds:Array<int>,sourceName:string,status:string,payoutType:string,fromDate:string,toDate:string,toDate:string,page:int,pageSize:int}
 */
export function getLogAdv(query) {
  return request({
    url: api.LOG_ADV,
    method: 'get',
    params: query,
  });
}
/**
 * 获取广告主列表
 *
 */
export function getSourceList() {
  return request({
    url: api.SOURCE_LIST,
    method: 'get',
  });
}

export function getAllSourceList() {
  return request({
    url: api.ALL_SOURCE_LIST,
    method: 'get',
  });
}

import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';
import { throttleFn } from './utils';
import store from '@/store';

const service = axios.create({
  baseURL: process.env.BASE_URL, //api的base_url
  timeout: 1000 * 100, //请求超时时间
});
const loginFailure = throttleFn(() => {
  Message({
    message: '账号已过期，请重新登录',
    type: 'error',
    duration: 5 * 1000,
  });
  router.replace('/login');
}, 5000);

service.interceptors.request.use(
  (config) => {
    config.headers['X-Request-Fingerprint'] = store.state.app.fingerprint;
    return config;
  },
  (error) => {
    console.error('request error:', error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        loginFailure();
      } else {
        return Promise.reject(error);
      }
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);
export default service;

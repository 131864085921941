import { login, logout } from 'api/user';

const state = {
  token: '',
  roles: [], //用户角色
  loginStatus: sessionStorage.getItem('loginStatus') || '',
  userInfo: JSON.stringify(sessionStorage.getItem('userInfo') || '{}') || {},
};
const getters = {
  roles: (state) => {
    return state.roles;
  },
};
const mutations = {
  //保存token
  COMMIT_TOKEN(state, object) {
    state.accountId = object.accountId;
  },
  //保存用户
  COMMIT_ROLE(state, roles) {
    state.roles = roles;
  },
  COMMIT_LOGIN_STATUS(state, status) {
    state.loginStatus = status;
    sessionStorage.setItem('loginStatus', status);
  },
  COMMIT_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  },
};
const actions = {
  login({ commit }, userInfo) {
    const { username, password, verifyCode } = userInfo;
    return new Promise((resolve, reject) => {
      const data = {
        username: username.trim(),
        password: password.trim(),
        verifyCode,
      };
      login(data)
        .then((response) => {
          const { code, message, result = {} } = response;
          if ([200, 1022, 1025, 2026, 1027, 1028].includes(code)) {
            if ([200, 1022].includes(code)) {
              commit('COMMIT_TOKEN', result);
              commit('COMMIT_USER_INFO', result);
              commit('COMMIT_LOGIN_STATUS', code === 200 ? '1' : '0');
            }
            resolve(response);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('COMMIT_TOKEN', '');
          commit('COMMIT_ROLES', '');
          commit('app/TAGES_LIST', []);
          commit('app/SET_BREAD_LIST', ['Dashboard']);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="wrapper">
    <Header />
    <Sidebar class="aside-container" />
    <div class="main-container" :class="isCollapse == true ? 'container_collapse' : ''">
      <Main v-if="loginStatus === '1'" />
      <el-dialog v-else :visible="dialogVisible" title="个人信息" width="500px" :show-close="false">
        <el-form
          ref="userInfoFormRef"
          :model="newUserInfo"
          label-width="100px"
          :rules="userInfoFormRules"
        >
          <el-form-item label="登录密码" prop="password">
            <el-input type="password" v-model="newUserInfo.password" show-password />
          </el-form-item>
          <el-form-item label="钉钉手机号" prop="phone">
            <el-input
              v-model="newUserInfo.phone"
              placeholder="请保证提供的手机号是钉钉的手机号！"
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handlerSaveUserInfo">保存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import Sidebar from './Sidebar.vue';
  import Header from './Header.vue';
  import Main from './Main.vue';
  import { mapState } from 'vuex';
  import { setCssVar } from 'utils/theme';
  import { initUserInfoApi } from 'api/user';

  const HEADER_BG_COLOR_VAR = '--header-bg-color';
  const SIDER_TEXT_COLOR_VAR = '--sider-text-color';
  const SIDER_TEXT_ACTIVE_COLOR_VAR = '--sider-text-active-color';

  export default {
    components: {
      Sidebar,
      Header,
      Main,
    },
    data: () => {
      return {
        dialogVisible: true,
        newUserInfo: {
          password: '',
          phone: '',
        },
        userInfoFormRules: {
          password: [{ required: true, message: '请输入登录密码', trigger: 'change' }],
          phone: [{ required: true, message: '请输入钉钉手机号', trigger: 'change' }],
        },
      };
    },
    computed: {
      ...mapState({
        isCollapse: (state) => state.app.isCollapse,
        loginStatus: (state) => state.user.loginStatus,
        userInfo: (state) => state.user.userInfo,
      }),
    },
    methods: {
      handlerSaveUserInfo() {
        this.$refs.userInfoFormRef?.validate(async (valid) => {
          if (!valid) {
            return;
          }
          try {
            const response = await initUserInfoApi({
              ...this.newUserInfo,
              accountId: this.userInfo.accountId,
            });
            if (response.code === 200) {
              this.dialogVisible = false;
              this.$message.success('user init success');
              this.$router.replace('/login');
            } else {
              this.$message.error(response.message);
            }
          } catch (e) {
            console.error('初始化失败', e);
          }
        });
      },
    },
    watch: {
      '$store.state.app.theme': {
        handler: function(val) {
          if (val === 'gourdmobi') {
            setCssVar(HEADER_BG_COLOR_VAR, '#337ab7');
            setCssVar(SIDER_TEXT_COLOR_VAR, '#337ab7');
            setCssVar(SIDER_TEXT_ACTIVE_COLOR_VAR, '#bdb7ff');
          } else if (val === 'bananamobiads') {
            setCssVar(HEADER_BG_COLOR_VAR, '#aacd99');
            setCssVar(SIDER_TEXT_COLOR_VAR, '#aacd99');
            setCssVar(SIDER_TEXT_ACTIVE_COLOR_VAR, '#47812b');
          }
        },
      },
    },
    mounted() {
      this.$store.dispatch('app/setTheme');
    },
  };
</script>
<style lang="scss" scoped>
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;

    .main-container {
      min-height: 100%;
      -webkit-transition: margin-left 0.28s;
      transition: margin-left 0.28s;
      margin-left: 220px;
      position: relative;
    }

    .container_collapse {
      margin-left: 64px;
    }
  }
</style>
